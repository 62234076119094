import React from 'react'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    const disableTransition =
      location && location.state && location.state.transition === false
    const timeout = 500
    const getTransitionStyles = disableTransition
      ? {}
      : {
          entering: {
            position: 'absolute',
            opacity: 0,
          },
          entered: {
            transition: `opacity ${timeout}ms ease-in-out`,
            opacity: 1,
          },
          exiting: {
            transition: `all ${timeout}ms ease-in-out`,
            opacity: 0,
          },
        }

    if (location) {
      return (
        <TransitionGroup>
          <ReactTransition
            key={location.pathname}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            {(status) => (
              <div
                style={{
                  ...getTransitionStyles[status],
                }}
              >
                {children}
              </div>
            )}
          </ReactTransition>
        </TransitionGroup>
      )
    } else {
      return 'Loading...'
    }
  }
}

export default Transition

import styled from 'styled-components'
import Button from '../ui/button'

export const Wrapper = styled.div`
  position: absolute;
  top: 2rem;
  width: 260px;
`

export const Link = styled(Button)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: -0.25rem;
  min-width: 12rem;
  margin-top: 0;
  opacity: 0;

  &:first-child {
    position: relative;
  }

  &:focus {
    opacity: 1;
    z-index: 15;
  }
`

import React from 'react'
import { Container } from '../ui/wrapper'
import * as Styles from './styles'

const SkipLinks = () => (
  <Container>
    <Styles.Wrapper id="skiplinks">
      <Styles.Link
        aria-label="Ga naar Hoofdinhoud"
        tabIndex="0"
        variant="secondary"
        to="#main"
      >
        Hoofdinhoud
      </Styles.Link>

      <Styles.Link
        aria-label="Ga naar Navigatie"
        tabIndex="0"
        variant="secondary"
        to="#navigation"
      >
        Navigatie
      </Styles.Link>

      <Styles.Link
        aria-label="Ga naar Footer"
        tabIndex="0"
        variant="secondary"
        to="#footer"
      >
        Footer
      </Styles.Link>
    </Styles.Wrapper>
  </Container>
)

export default SkipLinks

import { createGlobalStyle } from 'styled-components'
import SelectorIcon from '../images/icons/selector-icon.png'
import { worldRotate } from './animations'
import { device } from '../../theme'

export const GlobalStyle = createGlobalStyle`

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: ${(props) => props.theme.lineHieghts.text};
  font-size: ${(props) => props.theme.fontSizes.text};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeight.text};
  visibility: visible;
  font-family: ${(props) => props.theme.fontFamily.text};
  color: ${(props) => props.theme.colors.textColor};
  position: relative;
  background-color: ${(props) => props.theme.colors.bodyBgColor};

  @media ${device.small} {
    line-height: ${(props) => props.theme.lineHieghtsSmall.text};
    font-size: ${(props) => props.theme.fontSizesSmall.text};
  }

  &.no-overflow {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: ${(props) => props.theme.colors.headingColor};
    font-family: ${(props) => props.theme.fontFamily.heading};
    font-weight: ${(props) => props.theme.fontWeight.heading};
    margin-top: 0;
    margin-bottom: 0;
}

h1 {
  font-size: ${(props) => props.theme.fontSizes.h1};
  line-height: ${(props) => props.theme.lineHieghts.h1};

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.h1};
    line-height: ${(props) => props.theme.lineHieghtsSmall.h1};
  }
}

h2 {
  font-size: ${(props) => props.theme.fontSizes.h2};
  line-height: ${(props) => props.theme.lineHieghts.h2};

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.h2};
    line-height: ${(props) => props.theme.lineHieghtsSmall.h2};
  }
}

h3 {
  font-size: ${(props) => props.theme.fontSizes.h3};
  line-height: ${(props) => props.theme.lineHieghts.h3};
  
  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.h3};
    line-height: ${(props) => props.theme.lineHieghtsSmall.h3};
  }
}

h4 {
    font-size: ${(props) => props.theme.fontSizes.h4};
    line-height: ${(props) => props.theme.lineHieghts.h4};

    @media ${device.small} {
      font-size: ${(props) => props.theme.fontSizesSmall.h4};
      line-height: ${(props) => props.theme.lineHieghtsSmall.h4};
    }
}

h5 {
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHieghts.h5};

    @media ${device.small} {
      font-size: ${(props) => props.theme.fontSizesSmall.h5};
      line-height: ${(props) => props.theme.lineHieghtsSmall.h5};
    }
}

h6 {
  font-size: ${(props) => props.theme.fontSizes.h6};
  line-height: ${(props) => props.theme.lineHieghts.h6};

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.h6};
    line-height: ${(props) => props.theme.lineHieghtsSmall.h6};
  }
}

small {
  font-size: ${(props) => props.theme.fontSizes.small};
  line-height: ${(props) => props.theme.lineHieghts.small};

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.small};
    line-height: ${(props) => props.theme.lineHieghtsSmall.small};
  }
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input,
span {
  transition: ${(props) => props.theme.transition};
}

*:not(#gatsby-focus-wrapper):focus {
  outline: none !important;
  transform: translate(0, 0);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: dotted 1px #d90024;
  }
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a[class^="button"],
a[class*="button"],
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;

  &:focus::after {
    border-radius: 35px;
    box-shadow: 0 0 5px #d90024;
  }
}

a:hover,
button:hover {
  text-decoration: none;
  color: ${(props) => props.theme.colors.themeColor};
}

#gatsby-focus-wrapper:focus::before {
  content: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.mark,
mark {
  padding: 0 0;
  background-color: transparent;
}

select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.borderColorTwo};
  border-radius: 5px;
  background: ${(props) =>
    props.theme.colors
      .borderColorTwo} url(${SelectorIcon}) no-repeat center right 20px;
  background-color: rgb(248, 248, 248);
  -moz-appearance: none;
  -webkit-appearance: none;
  
  &:focus {
    background: ${(props) =>
      props.theme.colors
        .borderColorTwo} url(${SelectorIcon}) no-repeat center right 20px !important;
  }
}

img {
  max-width: 100%;
}

::selection {
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.themeColor};
}

form {
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

  input,
  textarea {
    &::placeholder {
      transition: ${(props) => props.theme.transition};
    }
  }
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus, select:focus,
textarea:focus {
  color: ${(props) => props.theme.colors.themeColor};
  border-color: ${(props) => props.theme.colors.themeColor};
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.site-wrapper-reveal {
  background-color: ${(props) => props.theme.colors.white};
}

@keyframes separator-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#googleMap-1,
#googleMap-2,
#googleMap-3 {
  height: 400px;
}

.wrapper {
  position: relative;
}

@media ${device.largeOnly} {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1200px;
  }

  .container-xl {
    max-width: 1440px;
  }
}

@media ${device.xlargeOnly} {
  .gutter-xl-70 {
    margin-right: -35px;
    margin-left: -35px;

    [class*="col"] {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

.xp-150 {
  @media ${device.largeOnly} {
    padding: 0 50px;
  }

  @media ${device.xlargeOnly} {
    padding: 0 150px;
  }
}

figure {
  margin: 0;
}

.worldRotate {
  animation: ${worldRotate} 20s linear infinite;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
`

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../theme'
import { GlobalStyle } from '../../assets/css/main-style'
import ScrollToTop from '../../components/ui/scroll-to-top'
import Transition from '../../components/transition'
import SkipLinks from '../../components/skip-links'

// import '../../assets/fonts/oswald/oswald.css'
import '../../assets/fonts/dia/dia.css'
import '../../assets/fonts/opensans/opensans.css'
import '../../assets/css/bootstrap.css'

const Layout = ({ children, location }) => (
  <ThemeProvider theme={theme}>
    <Transition location={location}>
      <GlobalStyle />
      <SkipLinks />
      {children}
      <ScrollToTop />
    </Transition>
  </ThemeProvider>
)

Layout.propTypes = {
  transition: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout

export const theme = {
  colors: {
    // Themes
    theme: '#d90024',
    themeMobility: '#8941c9',
    themeEconomy: '#3b99d0',
    themeSpace: '#00c87a',
    themeLabor: '#ff7900',

    // Typography
    icon: '#767676',
    text: '#666666',
    textDark: '#444444',
    textLight: '#ffffff',
    heading: '#d90024',

    // Background
    background: '#f9f9f9',
    backgroundLight: '#ffffff',
    backgroundDark: '#333333',

    // Border
    border: 'rgba(0, 0, 0, .1)',
    borderDark: '#767676',

    // Status
    success: '#008900',
    info: '#17a2b8',
    warning: '#ee8b00',
    danger: '#c03344',

    // Other
    star: '#ffb944',

    // Mapping theme design
    themeColor: '#d90024',
    themeColorBlack: '#333333',
    textColor: '#666666',
    headingColor: '#d90024',
    borderColor: 'rgba(0, 0, 0, .1)',
    borderColorTwo: '#767676',
    white: '#ffffff',
    black: '#000000',
    primary: '#d90024',
    secondary: '#808285',
    bodyBgColor: '#ffffff',
    silver: '#ababab',
    kimberly: '#6d70a6',
    whiteSmoke: '#f9f9f9',
  },
  lineHieghts: {
    impact: '88px',
    h1: '56px',
    h2: '40px',
    h3: '40px',
    h4: '32px',
    h5: '24px',
    h6: '24px',
    intro: '30px',
    introLarge: '36px',
    text: '24px',
    small: '18px',
    numeric: '36px',
  },
  lineHieghtsSmall: {
    impact: '48px',
    h1: '40px',
    h2: '32px',
    h3: '32px',
    h4: '24px',
    h5: '24px',
    h6: '24px',
    intro: '26px',
    introLarge: '32px',
    text: '24px',
    small: '16px',
    numeric: '32px',
  },
  fontFamily: {
    text: 'Open Sans',
    heading: 'Dia',
    fontAwesomePro: 'Font Awesome 5 Pro',
    fontAwesomeBrand: 'Font Awesome 5 Brands',
  },
  fontSizes: {
    impact: '72px',
    h1: '40px',
    h2: '30px',
    h3: '26px',
    h4: '20px',
    h5: '18px',
    h6: '16px',
    intro: '18px',
    introLarge: '22px',
    text: '16px',
    small: '14px',
    numeric: '22px',
  },
  fontSizesSmall: {
    impact: '40px',
    h1: '30px',
    h2: '26px',
    h3: '22px',
    h4: '18px',
    h5: '16px',
    h6: '14px',
    intro: '16px',
    introLarge: '18px',
    text: '14px',
    small: '12px',
    numeric: '20px',
  },
  fontWeight: {
    text: 400,
    heading: 'normal',
  },
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
}

export const size = {
  xxlarge: '1699px',
  xlarge: '1599px',
  large: '1199px',
  medium: '991px',
  small: '767px',
  xsmall: '575px',
  xxsmall: '480px',
  xxlargeOnly: '1700px',
  xlargeOnly: '1600px',
  largeOnly: '1200px',
  mediumOnly: '992px',
  smallOnly: '768px',
  xsmallOnly: '576px',
  xxsmallOnly: '481px',
}

export const device = {
  xxlarge: `(max-width: ${size.xxlarge})`,
  xlarge: `(max-width: ${size.xlarge})`,
  large: `(max-width: ${size.large})`,
  medium: `(max-width: ${size.medium})`,
  small: `(max-width: ${size.small})`,
  xsmall: `(max-width: ${size.xsmall})`,
  xxsmall: `(max-width: ${size.xxsmall})`,
  xxlargeOnly: `(min-width: ${size.xxlargeOnly})`,
  xlargeOnly: `(min-width: ${size.xlargeOnly})`,
  largeOnly: `(min-width: ${size.largeOnly})`,
  mediumOnly: `(min-width: ${size.mediumOnly})`,
  smallOnly: `(min-width: ${size.smallOnly})`,
  xsmallOnly: `(min-width: ${size.xsmallOnly})`,
  xxsmallOnly: `(min-width: ${size.xxsmallOnly})`,
}

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/datadisk/agent-1/_work/1/s/src/containers/layout/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Regio West-Brabant","short_name":"RWB","theme_color":"#d90024","background_color":"#ffffff","display":"standalone","scope":"/","start_url":"/","icon":"src/assets/images/favicon.png","icons":[{"src":"/icons/favicon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/icons/favicon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/favicon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/icons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/favicon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"62010d9eb4ed56cd9f30828c29e3e843"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html"],"useClassNames":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/actueel/","/themas/","/subsidieloket/","/subsidieloket/*","/over-de-regio/","/over-de-regio/*","/over-rwb/","/over-rwb/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WS8Q5MQ","enableWebVitalsTracking":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

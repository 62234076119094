import React, { useState, useEffect } from 'react'
import { MdArrowUpward } from 'react-icons/md'
import { ScrollTopButton } from './scroll-to-top.style'

const ScrollToTop = (props) => {
  const [stick, setStick] = useState(false)

  const onClickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    var position = window.pageYOffset

    const scrollHandler = () => {
      let scrollPos = window.pageYOffset

      if (scrollPos < 200) {
        setStick(false)
      } else if (scrollPos < position) {
        setStick(true)
      } else {
        setStick(false)
      }
      position = scrollPos
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [stick])

  return (
    <ScrollTopButton
      type="button"
      onClick={onClickHandler}
      isStick={stick}
      {...props}
      title="Terug naar boven"
    >
      <MdArrowUpward
        className="icon arrow-up"
        role="img"
        title="Terug naar boven"
      />
      <MdArrowUpward
        className="icon arrow-bottom"
        role="img"
        title="Ga naar het einden van de pagina"
      />
    </ScrollTopButton>
  )
}

export default ScrollToTop
